<template>
    <div class="ordertip">
        <headerOrder></headerOrder>
        <div class="tips">
           
            <div class="tip">
                 <div style="padding-top:64px;display:flex; justify-content: center;">
                    <img src="./success.png" alt="" srcset="" style="width:64px;height:64px">
                </div>
                <div class="tijiao">
                    提交成功
                </div>
                <p>请耐心等待海豚寻舱工作人员核对舱位，稍后会与您取得联系请保持手机畅通</p>
                <div>
                    <el-button type="success"  size="medium" @click="black">返回首页</el-button>
                    <el-button type="primary"  size="medium" @click="lookorder">查看订单</el-button>
                </div>
            </div>
        </div>
        <footerOrder></footerOrder>
    </div>
</template>

<script>
import headerOrder from './../common/headerOrder.vue'
import footerOrder from './../common/footer.vue'
export default {
    components:{ headerOrder,footerOrder},
    methods:{
        black(){
            this.$router.push("./")
        },
        lookorder(){
            this.$router.push("./orderInfo?orderId="+ this.$route.query.orderId)
        }
    }
}
</script>
<style lang="scss" scoped>
.tips{
    border-top: 1px solid #e6e6e6;
    margin-bottom: 20px;
}
.tip{
    margin: 0 auto;
    margin-top: 13px;
    width: 1120px;
    // height: 415px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 100px;
    
p{
    padding: 150px 0;
    font-size: 13px;
    padding-top: 50px;
    line-height: 1.8;
    color: #333;

}
div{
    width: 400px;
    display: flex;
    justify-content: space-between;
}
}
.el-button--success.is-plain{
    color: #333;
    border-color: #333;
    font-size: 12px;

}
.el-button--success{
    background-color: #fff;
}
.el-button--success:focus,
.el-button--success:hover {
  background: #1677ff;
  border-color: #1677ff;
  color: #fff;
}
.el-button--success{
    color: #333;
}
.tijiao{
    font-weight: 500;
    font-size: 24px;
    margin-top: 24px;
   display: flex;
    align-items: center;
    justify-content: center !important;
}
</style>